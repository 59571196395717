
import { LocalStorage, settings } from "@/libraries/storage";
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LoginView extends Vue {
    created(): void {
        this.url.hash = ""
    }

    get appApi(): string {
        return process.env.VUE_APP_API as string
    }

    get url(): URL {
        return new URL(location.href)
    }

    get settings(): LocalStorage {
        return settings
    }
}
